.gpt3__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
    animation: fadeInDown 0.5s;
}

.gpt3__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-links_logo{
    margin-right: 2rem;

}

.gpt3__navbar-links_logo img{
    width: 92.56px;
    transform: translateY(-10px);
    cursor: pointer;
    transition: all 0.2s;
}

.gpt3__navbar-links_logo img:hover{
    filter: drop-shadow(0px 0px 10px #1B262C);
}

.gpt3__navbar-links_container{
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p{
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin:  0rem 1rem;
    cursor: pointer;
}

.gpt3__navbar-links_container a:hover{
    border-bottom: 2px solid #fff;
    margin: 0 2px;
    padding-bottom: 2px;
}

.gpt3__navbar-links_container a{
    transition: all 0.2s ease;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button{
    padding: 0.5rem 1.5rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    left: 1153px;
    top: 55px;
    background: #F08A5D;
    border-radius: 20px;
    transition: all 0.2s;
}

.gpt3__navbar-sign p{
    transition: all 0.2s;
}

.gpt3__navbar-sign p:hover{
    text-decoration: underline;
    margin: 0px 18px;
}

.gpt3__navbar-sign button:hover{
    background: #F08A5D;
    padding: 0.5rem 1.6rem;
    filter: brightness(1.1);
}

.gpt3__navbar-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
}

.gpt3__navbar-menu svg{
    cursor: pointer;
}

.gpt3__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.gpt3__navbar-menu_container p{
    margin: 1rem 0;

}

.gpt3__navbar-menu_container-links-sign{
    display: none;
}


@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}

@keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translate3d(0, -10px, 0);
    }
  
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }